import axios from 'axios';
import type { SolutionFeedback } from '../types/feedback';

const API_URL = import.meta.env.VITE_REACT_APP_NOCODB_API_URL;
const API_TOKEN = import.meta.env.VITE_REACT_APP_NOCODB_API_TOKEN;

const nocodbApi = axios.create({
  baseURL: API_URL,
  headers: {
    'xc-token': API_TOKEN
  }
});

const WEBHOOK_URLS = {
  general: 'https://n8n-api.fixfinder.com/webhook/48a227bd-f7d3-40e2-b44f-28841ff93c70',
  solution: 'https://n8n-api.fixfinder.com/webhook/db0882f8-8aa9-4f82-8a19-f991c026bf9c'
};

const submitToWebhook = async (data: any) => {
  try {
    const webhookUrl = WEBHOOK_URLS[data.type === 'solution_feedback' ? 'solution' : 'general'];
    await axios.post(webhookUrl, data);
  } catch (error) {
    console.error('Webhook submission error:', error);
    // Don't throw error to prevent disrupting main flow
  }
};

// Cache configuration
const CACHE_DURATION = 5000; // 5 seconds

export const submitSweepstakesAcceptance = async (email: string) => {
  try {
    // Submit to NoCoDB
    await nocodbApi.post('/api/v2/tables/mbddtudaf7xmvre/records', {
      Client: email,
      Terms: 'Yes'
    });

    // Submit to webhook as backup
    try {
      await fetch('https://n8n-api.fixfinder.com/webhook/5fc3fff6-efe8-4252-974e-58475cf51c93', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          Client: email,
          Email: email
        })
      });
    } catch (webhookError) {
      console.warn('Webhook submission failed:', webhookError);
      // Don't throw error to prevent disrupting main flow
    }

    return 'Sweepstakes acceptance recorded successfully';
  } catch (error) {
    console.error('Error submitting sweepstakes acceptance:', error);
    throw handleApiError(error, 'Submit Sweepstakes Acceptance');
  }
};

interface CacheEntry<T> {
  data: T;
  timestamp: number;
}

interface CacheStore {
  userRecords: { [key: string]: CacheEntry<any> };
  progress: { [key: string]: CacheEntry<any> };
}

interface IntegrationRecord {
  Id: string;
  Client: string;
  Integration1?: string;
  Integration2?: string;
  Integration3?: string;
  Integration4?: string;
  Integration5?: string;
  Integration6?: string;
  Integration7?: string;
  Integration8?: string;
  Integration9?: string;
  Integration10?: string;
}

interface IntegrationUpdateBody {
  Id: string;
  Client: string;
  Integration1: string;
  Integration2: string;
  Integration3: string;
  Integration4: string;
  Integration5: string;
  Integration6: string;
  Integration7: string;
  Integration8: string;
  Integration9: string;
  Integration10: string;
}

interface IntegrationCreateBody {
  Client: string;
  Integration1?: string;
  Integration2?: string;
  Integration3?: string;
  Integration4?: string;
  Integration5?: string;
  Integration6?: string;
  Integration7?: string;
  Integration8?: string;
  Integration9?: string;
  Integration10?: string;
}

interface DesktopFeedback {
  Applications: number;
  Knowledge: number;
  Migrations: number; 
  Status_Page: number;
  Ticketing: number;
  Bots: number;
  Automation: number;
  addressesChallenges: boolean | null;
  Challenges: string;
  Importance: string;
  paymentConsideration: boolean | null;
  Payment: string;
  Competitors: string;
  Additional: string;
  bookFollowUp: boolean | null;
  Call: string;
  Client: string;
  Email?: string;
  Troubleshoot?: number;
}

const cache: CacheStore = {
  userRecords: {},
  progress: {}
};


const isValidCacheKey = (key: string): boolean => {
  return typeof key === 'string' && key.length > 0 && /^[a-zA-Z0-9_-]+$/.test(key);
};

const safeCacheAccess = <T>(cache: { [key: string]: T }, key: string): T | undefined => {
  if (!isValidCacheKey(key)) {
    console.error('Invalid cache key attempted:', key);
    return undefined;
  }
  return cache[key];
};

const safeCacheSet = <T>(cache: { [key: string]: T }, key: string, value: T): void => {
  if (!isValidCacheKey(key)) {
    console.error('Invalid cache key attempted:', key);
    return;
  }
  cache[key] = value;
};

const safeCacheDelete = (cache: { [key: string]: any }, key: string): void => {
  if (!isValidCacheKey(key)) {
    console.error('Invalid cache key attempted:', key);
    return;
  }
  delete cache[key];
};

interface GeneralFeedback {
  userId: string;
  rating: number;
  ideas: string;
  vendors: string;
  meeting: string;
}

const handleApiError = (error: any, context: string) => {
  if (axios.isAxiosError(error)) {
    const statusCode = error.response?.status;
    const errorMessage = error.response?.data?.msg || error.message;
    console.error(`API Error Response for ${context}:`, error.response?.data);
    return new Error(`Server error: ${statusCode} - ${errorMessage}`);
  }
  return error;
};

const isCacheValid = (entry: CacheEntry<any>) => {
  return Date.now() - entry.timestamp < CACHE_DURATION;
};

export const checkTenantAndUser = async (tenantId: string, userId: string, name: string, email: string, companyName: string) => {
  try {
    const response = await nocodbApi.get(`/api/v2/tables/mp3kfeza4acpmwh/records`, {
      params: {
        where: `(frontegg_tenant,eq,${tenantId})`
      }
    });

    const tenantRecords = response.data.list;

    if (tenantRecords.length > 0) {
      const userExists = tenantRecords.find((record: any) => record.frontegg_userid === userId);
      if (userExists) {
        return 'DB Lookup GOOD';
      } else {
        await createUser(tenantId, name, userId, email, companyName);
        return 'User added to existing tenant';
      }
    } else {
      await createUser(tenantId, name, userId, email, companyName);
      return 'New tenant and user created';
    }
  } catch (error) {
    console.error('Error communicating with NoCoDB:', error);
    throw handleApiError(error, 'Check Tenant and User');
  }
};

const createUser = async (tenantId: string, name: string, userId: string, email: string, companyName: string) => {
  try {
    await nocodbApi.post('/api/v2/tables/mp3kfeza4acpmwh/records', {
      frontegg_tenant: tenantId,
      frontegg_clientname: name,
      frontegg_accountname: companyName,
      frontegg_userid: userId,
      frontegg_email: email,
      solutions_progress: 0,
      desktop_progress: 0,
      solutions_checks: '',
      desktop_checks: ''
    });
  } catch (error) {
    throw handleApiError(error, 'Create User');
  }
};

const getUserRecord = async (userId: string) => {
  try {
    // Check cache first
    const cachedRecord = safeCacheAccess(cache.userRecords, userId);
    if (cachedRecord && isCacheValid(cachedRecord)) {
      return cachedRecord.data;
    }

    const response = await nocodbApi.get(`/api/v2/tables/mp3kfeza4acpmwh/records`, {
      params: {
        where: `(frontegg_userid,eq,${userId})`
      }
    });

    if (!response.data.list.length) {
      throw new Error('User not found');
    }

    // Cache the result
    safeCacheSet(cache.userRecords, userId, {
      data: response.data.list[0],
      timestamp: Date.now()
    });

    return response.data.list[0];
  } catch (error) {
    console.error('Error fetching user record:', error);
    throw error;
  }
};

export const updateProgress = async (userId: string, type: 'solutions' | 'desktop', progress: number) => {
  try {
    const record = await getUserRecord(userId);
    const field = type === 'solutions' ? 'solutions_progress' : 'desktop_progress';

    const requestBody = [{
      Id: record.Id,
      [field]: progress
    }];

    await nocodbApi.patch(`/api/v2/tables/mp3kfeza4acpmwh/records`, requestBody);

    // Invalidate cache
    safeCacheDelete(cache.userRecords, userId);
    safeCacheDelete(cache.progress, userId);

    return 'Progress updated successfully';
  } catch (error) {
    console.error('Error updating progress:', error);
    throw handleApiError(error, 'Update Progress');
  }
};

export const getProgress = async (userId: string): Promise<{ 
  solutions: number; 
  desktop: number;
  solutionsChecks: string;
  desktopChecks: string;
}> => {
  try {
    // Check cache first
    const cachedProgress = cache.progress[userId];
    if (cachedProgress && isCacheValid(cachedProgress)) {
      return cachedProgress.data;
    }

    const record = await getUserRecord(userId);
    const result = {
      solutions: record.solutions_progress || 0,
      desktop: record.desktop_progress || 0,
      solutionsChecks: record.solutions_checks || '',
      desktopChecks: record.desktop_checks || ''
    };

    // Cache the result
    cache.progress[userId] = {
      data: result,
      timestamp: Date.now()
    };

    return result;
  } catch (error) {
    console.error('Error fetching progress:', error);
    return { 
      solutions: 0, 
      desktop: 0,
      solutionsChecks: '',
      desktopChecks: ''
    };
  }
};

export const updateChecks = async (userId: string, type: 'solutions' | 'desktop', checks: string) => {
  try {
    const record = await getUserRecord(userId);
    const field = type === 'solutions' ? 'solutions_checks' : 'desktop_checks';

    const requestBody = [{
      Id: record.Id,
      [field]: checks
    }];

    await nocodbApi.patch(`/api/v2/tables/mp3kfeza4acpmwh/records`, requestBody);

    // Invalidate cache
    safeCacheDelete(cache.userRecords, userId);
    safeCacheDelete(cache.progress, userId);

    return 'Checks updated successfully';
  } catch (error) {
    console.error('Error updating checks:', error);
    throw handleApiError(error, 'Update Checks');
  }
};

export const submitFeedback = async ({ userId, rating, ideas, vendors, meeting }: GeneralFeedback) => {
  try {
    console.log('Looking up customer for general feedback:', { userId });

    const customer = await getUserRecord(userId);
    const customerEmail = customer.frontegg_email;

    if (!customerEmail) {
      throw new Error('Customer email not found');
    }

    console.log('Found customer email:', customerEmail);

    const feedbackData = {
      Client: customerEmail,
      Rating: rating,
      Ideas: ideas,
      Vendors: vendors,
      Meeting: meeting
    };

    console.log('Submitting general feedback:', feedbackData);
    const response = await nocodbApi.post('/api/v2/tables/mg7gpqvapff6r22/records', feedbackData);
    await submitToWebhook({ type: 'general_feedback', ...feedbackData });

    console.log('General feedback submission response:', response.data);
    return 'Feedback submitted successfully';
  } catch (error) {
    console.error('General feedback submission error:', error);
    throw handleApiError(error, 'Submit General Feedback');
  }
};

export const submitSolutionFeedback = async (feedback: SolutionFeedback) => {
  try {
    console.log('Submitting solution feedback:', feedback);

    const customer = await getUserRecord(feedback.clientId);
    const customerEmail = customer.frontegg_email;

    if (!customerEmail) {
      throw new Error('Customer email not found');
    }

    console.log('Found customer email:', customerEmail);

    console.log('Raw feedback object:', feedback);
    console.log('Paying value type:', typeof feedback.paying);
    console.log('Paying value:', feedback.paying);

    const feedbackData = {
      Client: customerEmail,
      Rating: feedback.rating,
      Challenges: feedback.challenges || 'N/A',
      Describe: feedback.describe || '',
      Paying: feedback.paying === null || feedback.paying === undefined ? 'N/A' : feedback.paying ? 'Yes' : 'No',
      Vendors: feedback.vendors || '',
      Thoughts: feedback.thoughts || '',
      Solution: feedback.solutionName,
      Meeting: feedback.meeting || 'N/A'
    };

    console.log('Processed feedback data:', feedbackData);

    const response = await nocodbApi.post('/api/v2/tables/myl5lky7ck14c7y/records', feedbackData);
    await submitToWebhook({ type: 'solution_feedback', ...feedbackData });

    console.log('Solution feedback submission response:', response.data);
    return 'Solution feedback submitted successfully';
  } catch (error) {
    console.error('Solution feedback submission error:', error);
    throw handleApiError(error, 'Submit Solution Feedback');
  }
};

export const submitDesktopFeedback = async (userId: string, feedback: DesktopFeedback) => {
  try {
    console.log('Submitting desktop feedback:', feedback);

    const customer = await getUserRecord(userId);
    const customerEmail = customer.frontegg_email;
    const customerName = customer.frontegg_accountname;

    if (!customerEmail) {
      throw new Error('Customer email not found');
    }
    if (!customerName) {
      throw new Error('Customer name not found');
    }

    console.log('Found customer email:', customerEmail);
    console.log('Found customer name:', customerName);

    const feedbackData = {
      Client: customerName,
      Email: customerEmail,
      Applications: feedback.Applications,
      Knowledge: feedback.Knowledge,
      Automation: feedback.Automation,
      Status_Page: feedback.Status_Page,
      Migrations: feedback.Migrations,
      Ticketing: feedback.Ticketing,
      Bots: feedback.Bots,
      Troubleshoot: feedback.Applications,
      Challenges: feedback.addressesChallenges === null || feedback.addressesChallenges === undefined ? "N/A" : feedback.addressesChallenges ? "Yes" : "No",
      Importance: feedback.Importance || "",
      Payment: feedback.paymentConsideration === null || feedback.paymentConsideration === undefined ? "N/A" : feedback.paymentConsideration ? "Yes" : "No",
      Competitors: feedback.Competitors || "",
      Additional: feedback.Additional || "",
      Call: feedback.bookFollowUp === null || feedback.bookFollowUp === undefined ? "N/A" : feedback.bookFollowUp ? "Yes" : "No"
    };

    console.log('Submitting desktop feedback data:', feedbackData);

    let dbSuccess = false;
    let webhookSuccess = false;

    try {
      const response = await nocodbApi.post('/api/v2/tables/mm0rin98hs3o0te/records', feedbackData);
      console.log('Desktop feedback DB submission response:', response.data);
      dbSuccess = true;
    } catch (dbError) {
      console.warn('DB submission failed:', dbError);
    }

    try {
      const webhookResponse = await fetch('https://n8n-api.fixfinder.com/webhook/d11dca2f-c716-4768-a95a-1008dff374ce', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ type: 'desktop_feedback', ...feedbackData })
      });
      webhookSuccess = webhookResponse.ok;
    } catch (webhookError) {
      console.warn('Webhook submission failed:', webhookError);
    }

    if (!dbSuccess && !webhookSuccess) {
      throw new Error('Both DB and webhook submissions failed');
    }

    return 'Desktop feedback submitted successfully';
  } catch (error) {
    console.error('Desktop feedback submission error:', error);
    throw handleApiError(error, 'Submit Desktop Feedback');
  }
};

// Get existing integration feedback
export const getIntegrationFeedback = async (email: string): Promise<IntegrationRecord[]> => {
  try {
    const response = await nocodbApi.get(`/api/v2/tables/mwbj5cox76or375/records`, {
      params: {
        where: `(Client,eq,${email})`
      }
    });

    return response.data.list || [];
  } catch (error) {
    console.error('Error fetching integration feedback:', error);
    throw handleApiError(error, 'Get Integration Feedback');
  }
};

// Submit integration feedback
export const submitIntegrationFeedback = async (email: string, integrations: { name: string; description: string }[]) => {
  try {
    // Get existing records
    const existingRecords = await getIntegrationFeedback(email);

    if (existingRecords.length > 0) {
      // Update existing record
      const record = existingRecords[0];
      const updateData: IntegrationUpdateBody = {
        Id: record.Id,
        Client: email,
        Integration1: '',
        Integration2: '',
        Integration3: '',
        Integration4: '',
        Integration5: '',
        Integration6: '',
        Integration7: '',
        Integration8: '',
        Integration9: '',
        Integration10: ''
      };

      // Map integrations to Integration fields
      integrations.forEach((integration, index) => {
        if (index < 10) {
          const key = `Integration${index + 1}` as keyof IntegrationUpdateBody;
          updateData[key] = `${integration.name}: ${integration.description}`;
        }
      });

      // Update the record
      await nocodbApi.patch('/api/v2/tables/mwbj5cox76or375/records', [updateData]);
    } else {
      // Create new record
      const createData: IntegrationCreateBody = {
        Client: email
      };

      // Map integrations to Integration fields
      integrations.forEach((integration, index) => {
        if (index < 10) {
          const key = `Integration${index + 1}` as keyof IntegrationCreateBody;
          createData[key] = `${integration.name}: ${integration.description}`;
        }
      });

      await nocodbApi.post('/api/v2/tables/mwbj5cox76or375/records', createData);
    }

    return 'Integration feedback submitted successfully';
  } catch (error) {
    console.error('Error submitting integration feedback:', error);
    throw handleApiError(error, 'Submit Integration Feedback');
  }
};

export const submitInvestmentFeedback = async (email: string, type: string, range: string, advisory: boolean) => {
  try {
    // Submit to NocoDB
    const nocodbResponse = await nocodbApi.post('/api/v2/tables/m507vkvuexfs0xw/records', {
      Email: email,
      Type: type,
      Range: range,
      Advisory: advisory ? 'Yes' : 'No'
    });

    // Submit to N8N webhook
    const webhookResponse = await fetch('https://n8n-api.fixfinder.com/webhook/7371dea5-bc90-4be1-a55f-212d62a31dad', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        Email: email,
        Type: type,
        Range: range,
        Advisory: advisory ? 'Yes' : 'No'
      })
    });

    if (!webhookResponse.ok) {
      console.error('Webhook submission failed:', webhookResponse);
    }

    console.log('Investment feedback submission response:', nocodbResponse.data);
    return 'Investment feedback submitted successfully';
  } catch (error) {
    console.error('Investment feedback submission error:', error);
    throw handleApiError(error, 'Submit Investment Feedback');
  }
};